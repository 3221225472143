<div class="row gy-7 mb-9">
    <ng-container *ngFor="let entry of keyboardShortcutsComponentsSimplified$ | async; index as index">
        @if (!readOnly || entry.componentName === ComponentName.Global || entry.componentName === (component$ | async)) {
            @if (index) {
                <div class="col-12">
                    <p-divider styleClass="my-0"></p-divider>
                </div>
            }

            <div class="col-12">
                <div class="row"
                     [ngClass]="GUTTER_Y">
                    <div class="col-md col-12">
                        <span class="rad-text-large-bold rad-text-color-label">{{ 'enums.ComponentName.' + entry.componentName | translate }}</span>
                    </div>
                    @if (!readOnly) {
                        <div class="col-md-auto col-12">
                            <p-button styleClass="w-below-md-100"
                                      [label]="'revertToDefaults' | translate"
                                      [outlined]="true"
                                      [severity]="Severity.DANGER"
                                      (onClick)="revertToDefaultPrompt(entry.componentName)"></p-button>
                        </div>
                    }

                    @for (innerEntry of entry.keyboardShortcutsSimplified; track innerEntry.command) {
                        <div class="col-12">
                            <div class="row gy-2 align-items-center">
                                <div class="col-md-4 col-12">
                                    <div class="rad-text-normal-bold rad-text-color-label">{{ 'enums.Command.' + innerEntry.command | translate }}</div>
                                    @if (innerEntry.value) {
                                        @if (!innerEntry.type) {
                                            <div>{{ innerEntry.value }}</div>
                                        }
                                        @if (innerEntry.type === KeyboardShortcutType.NAVIGATION) {
                                            <div>{{ ('enums.ComponentName.' + SITE_ROUTING.SITE_NODE_IDS_COMPONENT_NAMES[innerEntry.value]) | translate }}</div>
                                        }
                                    }
                                </div>
                                <div class="col-12"
                                     [ngClass]="readOnly ? 'col-md-auto' : 'col-md-4'">
                                    <span>{{ innerEntry.keysText }}</span>
                                </div>
                                @if (innerEntry.command !== Command.KEYBOARD_SHORTCUTS_HELP && !readOnly) {
                                    <div class="col-md-4 col-12">
                                        <div class="d-flex flex-md-row flex-column-reverse justify-content-end"
                                             [ngClass]="GAP_BUTTONS">
                                            <p-button styleClass="w-below-md-100"
                                                      [label]="'assign' | translate"
                                                      (onClick)="initiateAssignShortcut(entry.componentName, innerEntry.command, innerEntry.value)"></p-button>
                                            <p-button styleClass="w-below-md-100"
                                                      [label]="'remove' | translate"
                                                      [severity]="Severity.DANGER"
                                                      (onClick)="removePrompt(entry.componentName, innerEntry.command)"></p-button>
                                            <p-button styleClass="w-below-md-100"
                                                      [label]="'revertToDefault' | translate"
                                                      [outlined]="true"
                                                      [severity]="Severity.WARNING"
                                                      (onClick)="revertToDefaultPrompt(entry.componentName, innerEntry.command)"></p-button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        }
    </ng-container>
</div>

@if (!readOnly) {
    <p-button class="text-end"
              styleClass="w-below-md-100"
              [label]="'revertAllToDefaults' | translate"
              [severity]="Severity.DANGER"
              (onClick)="revertToDefaultPrompt()"></p-button>

    <app-keyboard-shortcut-assign></app-keyboard-shortcut-assign>
}