import {AsyncPipe} from '@angular/common';
import {Component} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {map, Observable, withLatestFrom} from 'rxjs';
import {ConfirmDialogComponent} from 'src/app/components/utilities/confirm-dialog/confirm-dialog.component';
import {ComponentName} from 'src/app/enums/core/component-name.enum';
import {KeyboardCode} from 'src/app/enums/core/keyboard-code.enum';
import {KEYBOARD_CODE_KEY_MAP} from 'src/app/helpers/core/keyboard-keys.helper';
import {DEFAULT_KEYBOARD_SHORTCUTS} from 'src/app/helpers/core/keyboard-shortcuts';
import {KeyboardShortcutsSettings} from 'src/app/interfaces/core/keyboard-shortcuts-settings.intrerface';
import {mapKeyboardCodeListToKeyboardShortcut} from 'src/app/mappers/keyboard-shortcuts.mapper';
import {ButtonsModule} from 'src/app/modules/buttons/buttons.module';
import {KeyboardShortcutsService} from 'src/app/services/core/keyboard-shortcuts.service';

const RAD_STANDALONE_COMPONENTS = [
    ConfirmDialogComponent
];

const RAD_MODULES = [
    ButtonsModule
];

@Component({
    selector: 'app-keyboard-shortcut-assign',
    standalone: true,
    imports: [
        AsyncPipe,
        TranslateModule,
        ...RAD_STANDALONE_COMPONENTS,
        ...RAD_MODULES
    ],
    templateUrl: './keyboard-shortcut-assign.component.html'
})
export class KeyboardShortcutAssignComponent {
    protected keyboardShortcut$!: Observable<KeyboardCode[]>;
    protected keyboardShortcuts$!: Observable<KeyboardShortcutsSettings>;
    protected error$!: Observable<boolean>;

    protected readonly KEYBOARD_CODE_KEY_MAP = KEYBOARD_CODE_KEY_MAP;

    constructor(
        protected keyboardShortcutsService: KeyboardShortcutsService
    ) {
        this.keyboardShortcut$ = this.keyboardShortcutsService.keyboardShortcut$;
        this.keyboardShortcuts$ = this.keyboardShortcutsService.keyboardShortcuts$;
        this.error$ = this.prepareError();
    }

    private prepareError() {
        return this.keyboardShortcut$.pipe(
            withLatestFrom(this.keyboardShortcuts$),
            map(([keyboardShortcut, keyboardShortcuts]) => {
                const componentName = this.keyboardShortcutsService.activeItem?.componentName;
                if (!componentName) return false;

                const parsedKeyboardShortcut = mapKeyboardCodeListToKeyboardShortcut(keyboardShortcut);
                const globalKeyboardShortcutsSetting = keyboardShortcuts[ComponentName.Global];
                const keyboardShortcutsSetting = keyboardShortcuts[componentName];
                return this.keyboardShortcutsService.checkModifiersAndKeys(parsedKeyboardShortcut, globalKeyboardShortcutsSetting) ||
                    this.keyboardShortcutsService.checkModifiersAndKeys(parsedKeyboardShortcut, keyboardShortcutsSetting);
            })
        );
    }

    protected onSave(keys: KeyboardCode[]) {
        if (!this.keyboardShortcutsService.activeItem) return;

        const defaultKeyboardShortcut = DEFAULT_KEYBOARD_SHORTCUTS[this.keyboardShortcutsService.activeItem.componentName][this.keyboardShortcutsService.activeItem.command];
        const keyboardShortcut = mapKeyboardCodeListToKeyboardShortcut(keys, defaultKeyboardShortcut);
        const callback = () => this.keyboardShortcutsService.reset();
        this.keyboardShortcutsService.saveKeyboardShortcuts(this.keyboardShortcutsService.activeItem.componentName, this.keyboardShortcutsService.activeItem.command, keyboardShortcut, callback);
    }
}